import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    memberLogin: "0",
    adminLogin: false,
    id_member: "",
    company_name: "",
    // isUploding: false,
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
