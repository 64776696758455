import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueGtag from "vue-gtag";

Vue.config.productionTip = false;

Vue.use(
  VueGtag,
  {
    pageTrackerExcludedRoutes: [
      "/login",
      "/join",
      "/findpw",
      "/ppdetail",
      "/excel",
      "/exceldownload",
      "/chargelog",
      "/uselog",
      "/myinfo",
      "/noticeboard",
      "/noticecontent",
      "/manage/point",
      "/manage/refund",
      "/manage/member",
      "/manage/member/info",
      "/manage/scan",
      "/manage/grlook",
      "/manage/pplook",
    ],
    config: {
      id: "G-YHBJF52W12",
      params: {
        send_page_view: false,
      },
    },
  },
  router
);

// Vue.use(
//   VueGtag,
//   {
//     // pageTrackerExcludedRoutes: [
//     //   "/login",
//     //   "/join",
//     //   "/findpw",
//     //   "/ppdetail",
//     //   "/excel",
//     //   "/exceldownload",
//     //   "/chargelog",
//     //   "/uselog",
//     //   "/myinfo",
//     //   "/noticeboard",
//     //   "/noticecontent",
//     //   "/manage/point",
//     //   "/manage/refund",
//     //   "/manage/member",
//     //   "/manage/member/info",
//     //   "/manage/scan",
//     //   "/manage/grlook",
//     //   "/manage/pplook",
//     // ],
//     config: {
//       id: "G-YHBJF52W12",
//       params: {
//         send_page_view: false,
//       },
//     },
//   },
//   router
// );

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
