<template>
  <v-app>
    <v-navigation-drawer
      v-if="memberLogin == '1' && !isHomePage && !isLoginPage"
      app
      permanent
      class="indigo lighten-5"
      
      touchless
    >
      <v-list-item class="ma-1 justify-center">
        <v-img
          src="여권허브-영문.png"
          max-width="200"
          lazy
          class="mt-2"
          @click="go('/')"
          style="cursor: pointer"
        ></v-img>
      </v-list-item>
      <v-list-item class="justify-center">
        <h3 style="color: #1976d2">여행사 여권스캔 서비스</h3>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item two-line>
        <v-list-item-avatar tile
          ><v-icon large color="primary"
            >mdi-account-badge</v-icon
          ></v-list-item-avatar
        >
        <v-list-item-content>
          <v-list-item-title>ID: {{ id_member }}</v-list-item-title>
          <v-list-item-subtitle
            >회사명: {{ company_name }}</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-btn
            @click="dialog = true"
            v-if="memberLogin == '1'"
            color="primary"
            tile
            small
            text
            ><v-icon left> mdi-logout </v-icon>
            <h4>로그아웃</h4>
          </v-btn>
        </v-list-item-content>
      </v-list-item>
      <v-list dense>
        <v-list-group
          :value="true"
          v-for="item in items"
          :key="item.title"
          :prepend-icon="item.icon"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="child in item.items"
            :key="child.title"
            :to="child.to"
          >
            <v-list-item-content>
              <v-list-item-title v-text="child.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item
          color="primary"
          v-for="(item, i) in boardItem"
          :key="item.title"
          :to="item.to"
          :value="true"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          color="primary"
          v-for="(item, i) in infoItem"
          :key="item.title"
          :to="item.to"
          :value="true"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group
          v-if="adminLogin"
          v-for="item in adminItem"
          :key="item.title"
          :prepend-icon="item.icon"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="child in item.items"
            :key="child.title"
            :to="child.to"
          >
            <v-list-item-content>
              <v-list-item-title v-text="child.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="indigo darken-4" dark>

      <div class="d-flex" v-if="isHomePage">
        <v-img src="/여권허브-영문(흰색).png" width="150"></v-img>
      </div>
      <v-app-bar-title class="ml-5" 
        ><h3 v-if="!isHomePage" style="width:500px">
          {{ currentPageName }}
        </h3>
        <h3 v-else-if="isHomePage" style="width:350px">여행사 여권스캔 서비스</h3>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <div v-if="isHomePage">
        <v-btn dense text @click="go('/grlook')"><h4>여권스캔</h4></v-btn>
        <v-btn dense text @click="go('/pointcharge')"><h4>결제관리</h4></v-btn>
        <v-btn dense text @click="go('/noticeboard')"><h4>게시판</h4></v-btn>
        <v-btn dense text @click="go('/myinfo')"><h4>내정보</h4></v-btn>
      </div>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-toolbar-items class="mt-7">
        <!-- <v-icon>mdi-account-badge</v-icon><span>{{ id_member }}</span> -->
        <div v-if="memberLogin == '0'">
          <v-btn text @click="login()"
            ><v-icon left> mdi-login </v-icon>
            <h4>로그인</h4></v-btn
          >
          <v-btn @click="join()" text
            ><v-icon left> mdi-account-multiple-plus </v-icon>
            <h4>회원가입</h4></v-btn
          >
        </div>
        <v-list-item class="mb-5" v-else-if="memberLogin == '1' && isHomePage">
          
          <v-list-item-content class="text-end mr-3" style="width: 145px">
            <v-list-item-title>ID: {{ id_member }}</v-list-item-title>
            <v-list-item-subtitle
              >회사명: {{ company_name }}</v-list-item-subtitle
            >
          </v-list-item-content>
          <v-btn icon small @click="dialog = true"
            ><v-icon>mdi-logout</v-icon></v-btn
          >
        </v-list-item>
      </v-toolbar-items>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
    
    <v-dialog v-model="dialog" max-width="300">
      <v-card>
        <v-card-title class="text-h8"> 로그아웃 하시겠습니까? </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" small text @click="logout"> 확인 </v-btn>
          <v-btn color="primary" small text @click="dialog = false">
            취소
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<style>
.v-text-field {
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 14px;
}

.v-text-field .v-label {
  font-size: 12px;
}

.v-text-field .v-input__control {
  min-height: 32px;
}
.v-data-table-header {
  white-space: nowrap;
}
.v-data-table-header > tr {
  background: #bbdefb;
}

.v-data-table__wrapper td {
  white-space: nowrap;
}
</style>

<script>
import { mapState, mapActions } from "vuex";
import axios from "axios";
// import img1 from "@/assets/여권허브-영문.png"

export default {
  name: "App",
  // watch:{
  //   $route(to, from) {
  //     this.isHomePage = to.path === "/";
  //   }
  // },
  computed: {
    ...mapState(["id_member", "company_name", "memberLogin", "adminLogin"]),
    isLoginPage() {
      return this.$route.path === "/login";
    },
    isHomePage() {
      return this.$route.path == "/";
    },
    isJoinPage() {
      return this.$route.path === "/join";
    },
    isPwPage() {
      return this.$route.path === "/finpw";
    },
  },

  created() {
    // this.intervalId = setInterval(this.getInfo(), 3600000);
    if (this.$route.name == "홈") {
      this.currentPageName = "";
    } else {
      this.currentPageName = this.$route.name; // 초기 값 설정
    }
    // 라우터가 변경될 때마다 페이지 이름 업데이트
    this.$router.afterEach((to, from) => {
      if (this.$route.name == "홈") {
        this.currentPageName = "";
      } else {
        this.currentPageName = this.$route.name;
      }
    });
    this.switchToDesktopSite();
    // // // console.log(this.currentPageName);
    // const storedCompanyName = sessionStorage.getItem('memberLogin');
    // if (storedCompanyName !== null) {
    //   this.memberLogin = storedCompanyName;
    // }
  },
  data: () => ({
    // memberLogin:false,
    // memberLogin: '0',
    img1: null,
    showPopup1: false,
    showPopup2: false,
    currentPageName: "",
    id: "",
    dialog: false,
    isMobile: false,
    infoItem: [
      {
        title: "내 정보",
        //items: [{ title: "게시판 조회", to: "/board" }],
        to: "/myinfo",
        icon: "mdi-account-details",
      },
    ],
    boardItem: [
      {
        title: "게시판",
        to: "/noticeboard",
        icon: "mdi-bulletin-board",
      },
    ],
    adminItem: [
      {
        title: "관리자",
        items: [
          { title: "회원관리", to: "/manage/member" },
          { title: "여권스캔 관리", to: "/manage/scan" },
          { title: "결제관리", to: "/manage/point" },
          { title: "사용내역 관리", to: "/manage/uselog" },
          { title: "게시판 관리", to: "/manage/board" },
        ],
        // to: "/manage/point",
        icon: "mdi-shield-account",
      },
    ],
    items: [
      {
        title: "여권스캔",
        items: [
          { title: "그룹 등록 및 조회", to: "/grlook" },
          { title: "여권 등록 및 조회", to: "/pplook" },
          { title: "엑셀 서식 관리", to: "/excel" },
        ],
        // to: "/hospital",
        icon: "mdi-passport",
      },

      {
        title: "결제관리",
        items: [
          { title: "전자결제", to: "/pointcharge" },
          { title: "결제내역", to: "/chargelog" },
          { title: "사용내역", to: "/uselog" },
        ],
        // to: "/lic",
        icon: "mdi-credit-card-outline",
      },
      // {
      //   title: "게시판",
      //   items: [
      //     { title: "공지사항", to: "/noticeboard" },
      //     { title: "FAQ", to: "/faq" },
      //   ],
      //   // to: "/lic",
      //   icon: "mdi-bulletin-board",
      // },
    ],
    intervalId:null,
  }),

  mounted() {
    // // // console.log(this.$route.path);
    // this.img1 = img1;/
    // this.getInfo();
    // // // console.log(sessionStorage.getItem("memberLogin"));
    const today = new Date().toISOString().split("T")[0];
    if (today !== localStorage.getItem("popup1Date")) {
      this.showPopup1 = true;
    }
    if (today !== localStorage.getItem("popup2Date")) {
      this.showPopup2 = true;
    }
  },
  beforeDestroy() {
    // 컴포넌트가 파괴되기 전에 interval을 정리하여 메모리 누수를 방지
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  methods: {
    checkIfMobile() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      this.isMobile = /android|iPhone|iPad|iPod|windows phone/i.test(userAgent);
    },
    // 기본 웹 브라우저로 전환
    switchToDesktopSite() {
      this.checkIfMobile();
      // 뷰포트를 데스크톱 크기로 강제 설정
      if (this.isMobile) {
        const viewport = document.getElementById("viewport");
        if (viewport) {
          viewport.setAttribute("content", "width=1280, initial-scale=0.4");
        } else {
          // 뷰포트 메타 태그가 없을 경우 동적으로 추가
          const newViewport = document.createElement("meta");
          newViewport.name = "viewport";
          newViewport.id = "viewport";
          newViewport.content = "width=1280, initial-scale=0.4";
          document.head.appendChild(newViewport);
        }

        // 데스크톱 스타일을 적용할 클래스 추가
        document.body.classList.add("desktop-view");
      }
    },
    go(router) {
      this.$router.push(router).catch((err) => {});
    },
    // closePopup1() {
    //   if (document.getElementById("dontShow1").checked) {
    //     const today = new Date().toISOString().split("T")[0];
    //     localStorage.setItem("popup1Date", today);
    //   }
    //   this.showPopup1 = false;
    // },
    // closePopup2() {
    //   if (document.getElementById("dontShow2").checked) {
    //     const today = new Date().toISOString().split("T")[0];
    //     localStorage.setItem("popup2Date", today);
    //   }
    //   this.showPopup2 = false;
    // },
    join() {
      this.$router.push("/join").catch((e) => {});
    },
    menu() {},
    logout() {
      axios
        .get("/member/logout")
        .then((res) => {
          // // // console.log(res.data);
          // this.$store.state.memberLogin = false;
          sessionStorage.setItem("memberLogin", 0);

          // // // console.log(sessionStorage.getItem("memberLogin"));
          this.$store.state.memberLogin = sessionStorage.getItem("memberLogin");
          this.$store.state.adminLogin = false;
          this.$store.state.id_member = "";
          this.$store.state.company_name = "";
          this.$store.state.member_grade = "";
          this.$router.push("/").catch(() => {});
          this.dialog = false;
        })
        .catch((err) => {
          // // console.log(err);
        });
    },
    login() {
      this.$router.push("/login").catch((e) => {});
    },
    getInfo() {
      // // // console.log(this.$route.name);
      // // console.log(this.currentPageName);
      
      axios
        .get("/member/info")
        .then((res) => {
         
          console.log("Renew Session")
          if (res.data[0].id_member == "code1system" ||res.data[0].id_member =="1138675456") {
            this.$store.state.adminLogin = true;
          }
          
          this.$store.state.id_member = res.data[0].id_member;
          this.$store.state.company_name = res.data[0].company_name;

          sessionStorage.setItem("memberLogin", 1);
          this.$store.state.memberLogin = sessionStorage.getItem("memberLogin");
        })
        .catch((e) => {
         
          sessionStorage.setItem("memberLogin", 0);
          
          this.$store.state.memberLogin = sessionStorage.getItem("memberLogin");
         
          console.log("session expired")
         
            
            this.$router
              .push("/")
              .then(() => {
                alert("로그인 세션 만료");
              })
              .catch((e) => {});
          // }
        });
    },
  },
};
</script>
